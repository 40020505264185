import {NAvatarUploadValidate} from './file-upload-validators';
import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {getTimeInSeconds} from '@nit-core/methods';

//const EMAIL_REGEX = /^([a-zA-Z0-9]{0})([a-zA-Z0-9._-]+)@([a-zA-Z0-9]+((\.|-)[^\..])?[a-zA-Z0-9]*)(\.[^\..]([a-zA-Z]{1,10}))+$/;
const EMAIL_REGEX_NEW = /^[\._A-Za-z0-9-]+@[A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{1,})$/;
//const GMAIL_REGEX = /^([a-zA-Z0-9]+)([\_\.\-{1}])?([a-zA-Z0-9]+)\@gmail\.com$/;
const URL_REGEX = /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,})$/;

const NO_SPACES_REGEX = /^((?!\s+).)*$/;
const PROFILE_CODE_REGEX = /^[A-Za-z]{2}\d{6}$/;
const START_SPACES_NOT_ALLOWED = /^[ ]/;
const END_SPACES_NOT_ALLOWED = /[^ ]$/;
const CYRILLIC_ONLY_REGEX = /^[А-ЯЇІЄҐа-яїієґ0-9- ']*$/;
const PASSWORD_REGEX = /^((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?!.*[А-ЯЇІЄҐа-яїієґ ])(?=.*[$&+~,:₴".{}@;№`=?#|'<>^*()%!\\/\[\]])).{6,100}$/;

// const PASSWORD_REGEX = /^(?=.?[A-Z])(?=.?[a-z])(?=.?[0-9])(?=.[^\w_ ].*)([A-Za-z0-9]|[^\w_ ]){8,100}$/;
const EMAIL_FOR_SCHOOL_REGEX = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,8}$/;
const STREET_REGEX = /^(([А-ЯЇІЄҐ]|[1-9])[а-яїієґ0-9\- '"’”ʼ\/\.]*(?![А-Я])[ |-]{0,1})+[^ ]$/;
const ONLY_CYRILLIC = /^(([А-ЯЇІЄҐ][а-яїієґ\-'"’”ʼ]+[ |-]?)+)$/;
const ONLY_CYRILLIC_WITH_DOT = /^(([А-ЯЇІЄҐ][а-яїієґ\-'"’”ʼ.]+[ |-]?)+)$/;
const NUMBERS_NOT_ALLOWED = /^([^0-9]*)$/;
const LATIN_NOT_ALLOWED = /^([^A-Za-z]*)$/;
const LOGIN_REGEX = /^([a-zA-Z0-9$&+~,\-}{._:₴'";№`=?#|\\'@/<>^*()%!])*$/;
const APARTMENTS_NUMBER = /^([А-ЯЇІЄҐа-яїієґ0-9\/\-'"’”ʼ\.]+(?![_=№;$₴}{\[\]@!%,<?>^&*()~`+])[ ]*)*[^ ]$/;
const YEAR_REGEX = /^[1-2]{1}\d{3}$/;
const SCHOOL_NAME_REGEX = /^[VIXLCMD"' /,.№0-9-]*[А-ЯЇІЄҐа-яїієґ]+[-А-ЯЇІЄҐа-яїієґ0-9 "”/'’ʼ,.№IVXLCMD-]*$/;
const ADDRESS_REGEX = /^["/'.№-]*[А-ЯЇІЄҐа-яїієґ0-9 ]+[А-ЯЇІЄҐа-яїієґ0-9 "”/'’ʼ.№-]*$/;
const SPEC_SYMBOL = /[$&+~,:₴".{}@;№`=?#|'<>^*()%!\}\{\\/\[\]]{1,}/;
const HAS_CYRILLIC = /(?:.*[А-ЯЇІЄҐа-яїієґ])/;
//const EMAIL_STARTS = /^@/;
const CLASS_NAME = /(^([1-9]|1[01]))\-[А-ЯЇІЄҐа-яїієґ]{1}$/;

const avatarValidator = new NAvatarUploadValidate();
export const minBirthDate = new Date(1900, 0, 0);
export const maxBirthDate = new Date();

function isEmpty(value: any): boolean {
  return value == null || value.length === 0;
}

function hasLength(value: any): boolean {
  return value != null && typeof value.length === 'number';
}

export class NValidate {

  static required(control: AbstractControl): ValidationErrors | null {
    const message = 'Поле є обов\'язкове';

    return isEmpty(control.value) ? {required: {message}} : null;
  }

  static notEmpty(validationMessage?: string): ValidatorFn {
    const message = validationMessage ?? 'Поле є обов\'язкове';

    return (control: AbstractControl): ValidationErrors | null => {
      return isEmpty(control.value) ? {notEmpty: {message}} : null;
    };
  }

  static noWhitespace(control: AbstractControl): ValidationErrors | null {
    const message = 'Поле є обов\'язкове';

    return (control.value || '').trim().length ? null : {whitespace: {message}};
  }

  static maxLen(maxLength: number, validationMessage?: string): ValidatorFn {
    const message = validationMessage ?? `Поле повинно містити не більше ${maxLength} символів`;

    return (control: AbstractControl): ValidationErrors | null => {
      return hasLength(control.value) && control.value.length > maxLength
        ? {maxLen: {value: maxLength, actual: control.value.length, message}}
        : null;
    };
  }

  static maxDigits(maxDigits: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (value && value.toString().length > maxDigits) {
        return {maxDigits: {value: control.value}};
      }

      return null;
    };
  }

  static minLen(minLength: number, validationMessage?: string): ValidatorFn {
    const message = validationMessage ?? `Поле повинно містити не менше ${minLength} символів`;

    return (control: AbstractControl): ValidationErrors | null => {
      return (typeof control.value === 'string' ? !isEmpty(control.value) : hasLength(control.value)) && control.value.length < minLength
        ? {minLen: {value: minLength, actual: control.value.length, message}} : null;
    };
  }

  static minLenOptio(minLength: number, validationMessage?: string): ValidatorFn {
    const message = validationMessage ?? `Поле повинно містити не менше ${minLength} символів`;

    return (control: AbstractControl): ValidationErrors | null => {
      return hasLength(control.value) && control.value.length < minLength
        ? {maxLen: {value: minLength, actual: control.value.length, message}}
        : null;
    };
  }

  static email(formatMessage?: string): ValidatorFn {
    const format = formatMessage ?? 'Email введено неправильно';

    return (control: AbstractControl): ValidationErrors | null => {
      return !isEmpty(control.value) && !EMAIL_REGEX_NEW.test(control.value)
        ? {emailFormat: {message: format}} : null;
    };
  }

  static noStartSpaces(control: AbstractControl): ValidationErrors | null {
    return START_SPACES_NOT_ALLOWED.test(control.value) ?
      {noStartSpaces: {message: 'Пробіли на початку не дозволені'}} : null;
  }

  static noEndSpaces(control: AbstractControl): ValidationErrors | null {
    return END_SPACES_NOT_ALLOWED.test(control.value) ?
      {noEndSpaces: {message: 'Пробіли вкінці не дозволені'}} : null;
  }

  static noSpaces(formatMessage?: string): ValidatorFn {
    const format = formatMessage ?? 'Поле не повинно містити пробілів чи відступів';

    return (control: AbstractControl): ValidationErrors | null => {
      return !isEmpty(control.value) && !NO_SPACES_REGEX.test(control.value)
        ? {noSpaces: {message: format}} : null;
    };
  }

  static equalWith(fieldPath: string, validationMessage?: string): ValidatorFn {
    const message = validationMessage ?? 'Паролі не співпадають';

    return (control: AbstractControl): ValidationErrors | null => {
      return control.parent && control.parent.controls[fieldPath]?.value === control.value
        ? null
        : {equalWith: {message}};
    };
  }

  static profileCode(profileCodeMessage?: string): ValidatorFn {
    const format = profileCodeMessage ?? 'Код профілю введено неправильно';

    return (control: AbstractControl): ValidationErrors | null => {
      if (isEmpty(control.value) || !PROFILE_CODE_REGEX.test(control.value)) {
        return {profileCodeFormat: {message: format}};
      }
    };
  }

  static requiredTrue(control: AbstractControl): ValidationErrors | null {
    const message = 'Поле повинно бути відміченим';

    return control.value ? null : {requiredTrue: {message}};
  }

  static minLenWithMask(minLength: number, validationMessage?: string): ValidatorFn {
    const message = validationMessage ?? `Поле повинно містити не менше ${minLength} символів`;

    return (control: AbstractControl): ValidationErrors | null => {
      return control.value?.trim()?.length > 0 && control.value?.trim()?.length < minLength
        ? {maxLen: {value: minLength, actual: control.value.length, message}}
        : null;
    };
  }

  static maxFileSize(fileErrorMessage?: string): ValidatorFn {
    const message = fileErrorMessage ?? 'Розмір вкладення перевищує допустимий ліміт 2Мб';

    return (control: AbstractControl): ValidationErrors | null => {
      control.markAllAsTouched();

      return control.value && !avatarValidator.isSizeValid(control.value[0].size)
        ? {fileSize: {message}}
        : null;
    };
  }

  static allowedExtensions(errorMessage?: string): ValidatorFn | null {
    const message = errorMessage ?? 'Неправильний формат файлу';

    return (control: AbstractControl): ValidationErrors | null => {
      return control.value && control.value instanceof Array && !avatarValidator.isTypeValid(control.value[0].type)
        ? {fileSize: {message}}
        : null;
    };
  }

  static birthDate(errorMessage?: string): ValidatorFn | null {
    const message = errorMessage ?? 'Неправильна дата';

    return (control: AbstractControl): ValidationErrors | null => {
      return control.value && control.value instanceof Date && (control.value > maxBirthDate || control.value < minBirthDate)
        ? {birthDate: {message}}
        : null;
    };
  }

  static url(errorMessage?: string): ValidatorFn | null {
    const message = errorMessage ?? 'Посилання недійсне. Перевірте формат: http(s)://example.com/page';

    return (control: AbstractControl): ValidationErrors | null => {
      return !isEmpty(control.value) && !URL_REGEX.test(control.value)
        ? {url: {message}}
        : null;
    };
  }

  static CyrillicOnly(errorMessage?: string): ValidatorFn | null {
    const message = errorMessage ?? 'Має містити кирилицю';

    return (control: AbstractControl): ValidationErrors | null => {
      return !isEmpty(control.value) && !CYRILLIC_ONLY_REGEX.test(control.value)
        ? {cyrillicOnly: {message}} : null;
    };
  }

  static CyrillicNotAllowed(errorMessage?: string): ValidatorFn | null {
    const message = errorMessage ?? 'Поле не може містити кирилицю';

    return (control: AbstractControl): ValidationErrors | null => {
      return !isEmpty(control.value) && !LOGIN_REGEX.test(control.value)
        ? {cyrillicOnly: {message}} : null;
    };
  }

  static apartmentsNumber(errorMessage?: string): ValidatorFn | null {
    const message = errorMessage ?? 'Невірно введені дані';

    return (control: AbstractControl): ValidationErrors | null => {
      return !isEmpty(control.value) && !APARTMENTS_NUMBER.test(control.value)
        ? {apartmentsNumber: {message}} : null;
    };
  }

  static latinNotAllowed(errorMessage?: string): ValidatorFn | null {
    const message = errorMessage ?? 'Має містити кирилицю';

    return (control: AbstractControl): ValidationErrors | null => {
      return !isEmpty(control.value) && !LATIN_NOT_ALLOWED.test(control.value)
        ? {noLatin: {message}} : null;
    };
  }

  static ClassName(errorMessage?: string): ValidatorFn | null {
    const message = errorMessage ?? 'Неправильний формат назви класу';

    return (control: AbstractControl): ValidationErrors | null => {
      return !isEmpty(control.value) && !CLASS_NAME.test(control.value)
        ? {className: {message}} : null;
    };
  }

  static password(errorMessage?: string): ValidatorFn | null {
    const message = errorMessage ?? 'Пароль повинен містити мінімум одну велику латинську літеру та одну малу, одну цифру, і один спец.символ';

    return (control: AbstractControl): ValidationErrors | null => {
      return !isEmpty(control.value) && !PASSWORD_REGEX.test(control.value)
        ? {password: {message}} : null;
    };
  }

  static emailForSchool(errorMessage?: string): ValidatorFn | null {
    const format = errorMessage ?? 'Email введено неправильно';

    return (control: AbstractControl): ValidationErrors | null => {
      return !isEmpty(control.value) && !EMAIL_FOR_SCHOOL_REGEX.test(control.value)
        ? {email: {format}} : null;
    };
  }

  static cyrillicWithoutNumber(errorMessage?: string): ValidatorFn | null {
    const message = errorMessage ?? 'Невірно введені дані';

    return (control: AbstractControl): ValidationErrors | null => {
      return !isEmpty(control.value) && (!ONLY_CYRILLIC.test(control.value) ||
        !NUMBERS_NOT_ALLOWED.test(control.value) || !END_SPACES_NOT_ALLOWED.test(control.value))
        ? {names: {message}} : null;
    };
  }

  static cyrillicNoNumberAndWithDot(errorMessage?: string): ValidatorFn | null {
    const message = errorMessage ?? 'Невірно введені дані';

    return (control: AbstractControl): ValidationErrors | null => {
      return !isEmpty(control.value) && !ONLY_CYRILLIC_WITH_DOT.test(control.value) ||
        !isEmpty(control.value) && !NUMBERS_NOT_ALLOWED.test(control.value) || !isEmpty(control.value) && !END_SPACES_NOT_ALLOWED.test(control.value)
        ? {names: {message}} : null;
    };
  }

  static streetValidator(errorMessage?: string): ValidatorFn | null {
    const message = errorMessage ?? 'Невірно введені дані';

    return (control: AbstractControl): ValidationErrors | null => {
      return !isEmpty(control.value) && !STREET_REGEX.test(control.value) ? {names: {message}} : null;
    };
  }

  static year(errorMessage?: string): ValidatorFn | null {
    const message = errorMessage ?? 'Введіть коректно рік';

    return (control: AbstractControl): ValidationErrors | null => {
      return !isEmpty(control.value) && !YEAR_REGEX.test(control.value)
        ? {year: {message}} : null;
    };
  }
  static schoolName(errorMessage?: string): ValidatorFn | null {
    const message = errorMessage ?? 'Поле заповнено некоректно';

    return (control: AbstractControl): ValidationErrors | null => {
      return !isEmpty(control.value) && !SCHOOL_NAME_REGEX.test(control.value)
        ? {schoolName: {message}} : null;
    };
  }
  static address(errorMessage?: string): ValidatorFn | null {
    const message = errorMessage ?? 'Поле заповнено некоректно';

    return (control: AbstractControl): ValidationErrors | null => {
      return !isEmpty(control.value) && !ADDRESS_REGEX.test(control.value)
        ? {address: {message}} : null;
    };
  }

  static userEmail(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return !isEmpty(control.value) && !EMAIL_FOR_SCHOOL_REGEX.test(control.value) ?
        {emailFormat: {message: control.value.startsWith('@') ? 'Email не повинен починатись із @' : SPEC_SYMBOL.test(control.value)
          ? 'Електронна адреса містить недопустимі спецсимволи' :
          HAS_CYRILLIC.test(control.value) ? 'Електронна адреса містить кириличні літери' : 'Email введено неправильно'}} : null;
    };
  }

  static gradingValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const grading = control.value;

      return !grading.isGradingForbidden && !grading.isVerbalGradingActive && !grading.isLeveledGradingActive
        && !grading.isDigitalGradingActive ? {noGradingChosen: true} : null;
    };
  }

  static publishTimeValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formValue = control.value;

      return formValue.publishWholeSemester || formValue.date ? null : {noDateChosen: true};
    };
  }

  static timeConsistencyValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const formValue = control.value;
      const formValueFrom = getTimeInSeconds(formValue.fromTime);
      const formValueTo = getTimeInSeconds(formValue.toTime);

      return formValueFrom >= formValueTo ? {incorectTimeSequence: true} : null;
    };
  }

  static compareWithOtherLessons(lessonsForm): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const lessons = lessonsForm.value;
      const currentLesson = control.value;
      const previousLesson = lessons.find(lesson => lesson.index === currentLesson.index - 1);
      const nextLesson = lessons.find(lesson => lesson.index === currentLesson.index + 1);

      if (currentLesson.fromTime <= previousLesson?.toTime) {
        return {isLesserThanPrevious: true};
      }
      if (currentLesson.toTime >= nextLesson?.fromTime) {
        return {isGreaterThanNext: true};
      }

      return null;
    };
  }

  static anyClassChosen(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const parallels = control.value;
      const isClassChosen = parallels.some(parallel => parallel.classes.some(schoolClass =>
        schoolClass.isChosen));

      return isClassChosen ? null : {noClassChosen: true};
    };
  }

  static termSequenceValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const {fromTime, toTime} = control.value;

      return fromTime >= toTime ? {fromGreaterOfTo: true} : null;
    };
  }
}

