import {Pipe, PipeTransform} from '@angular/core';
import {CourseLesson} from '@nit-models';

@Pipe({
  name: 'coursesContextItems'
})
export class CoursesContextItemsPipe implements PipeTransform {

  transform(mode: string, dataItem: CourseLesson, index: number, total: number): boolean {
    return mode === 'edit'
      ? dataItem.content?.length !== 0
      : mode === 'move-up'
        ? index > 0
        : mode === 'move-down' ? index < total - 1 : true ;
  }

}
