export const environment = {
  production: false,
  authUrl: 'https://is.nit.lionwood.software',
  apiUrl: 'https://api.nit.lionwood.software',
  chatUrl: 'https://chat-api.nit.lionwood.software',
  url: 'https://web.nit.lionwood.software',
  maintenanceUrl: 'https://status.nit.lionwood.software',
  version:"dev-286481c9",
  firebase: {
    apiKey: 'AIzaSyB-i1xHx4T0FpaddL-89zQD3GmNAZBxgJQ',
    authDomain: 'nit-test-5675c.firebaseapp.com',
    projectId: 'nit-test-5675c',
    storageBucket: 'nit-test-5675c.appspot.com',
    messagingSenderId: '549649888745',
    appId: '1:549649888745:web:3308181d4a74937a128778',
    measurementId: 'G-V8CQ47RGSF',
    vapidKey: 'BGxyYI5HvEEFqOSxujpnJnZQVeuTfLevittyS3NXnEcUe91XRF8gOXUW_ref8CXEOZPkF5HPK2Dpc3NUuq34I20'
  },
  idGov: {
    disable: true,
    url: 'https://test.id.gov.ua/sign-widget/v2022testnew/',
    urlCheck: 'https://test.id.gov.ua/verify-widget/v2022testnew/'
  }
};
