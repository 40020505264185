import './core/global/extensions/array-sorting'; // TODO investigate index approach
import './core/global/extensions/string-trim'; // TODO investigate index approach
import {HttpInterceptorProviders} from './core/interceptors/interceptor-providers';
import {PermissionModule} from './core/permission/permission.module';
import {GlobalService} from '@nit-core/services/global/global.service';
import {OAuthModule, OAuthModuleConfig, OAuthStorage} from 'angular-oauth2-oidc';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule, LOCALE_ID, isDevMode} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {KendoModule, FormModule} from '@nit-modules';
import {ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NotFoundModule} from '@nit-components';
import {AuthService} from '@nit-core/auth';
import {LayoutModule} from '@angular/cdk/layout';
import '@progress/kendo-angular-intl/locales/uk/all';
import {registerLocaleData} from '@angular/common';
import localeUk from '@angular/common/locales/uk';
import {HammerConfigModule} from '@nit-core/modules/hammer-js.module';
import {defineLocale} from 'ngx-bootstrap/chronos';
import {ukLocale} from 'ngx-bootstrap/locale';
import {NgxPermissionsModule} from 'ngx-permissions';
import {NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule} from 'ngx-google-analytics';
import {TINYMCE_SCRIPT_SRC} from '@tinymce/tinymce-angular';
import {ServiceWorkerModule} from '@angular/service-worker';
import {UpdateVersionModalModule} from '@nit-core/components/update-version-modal/update-version-modal.module';

defineLocale('uk', ukLocale);
registerLocaleData(localeUk);

export function authConfigFactory(service: GlobalService): OAuthModuleConfig {
  return {
    resourceServer: {
      allowedUrls: [
        service.apiUrl,
        service.maintenanceUrl,
      ],
      sendAccessToken: true,
    }
  };
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    PermissionModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    KendoModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormModule,
    OAuthModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    NotFoundModule,
    LayoutModule,
    HammerConfigModule,
    NgxGoogleAnalyticsModule.forRoot('G-BG32K1WQ4Q'),
    NgxGoogleAnalyticsRouterModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),
    UpdateVersionModalModule,
  ],
  providers: [
    AuthService,
    GlobalService,
    HttpInterceptorProviders,
    {
      provide: OAuthModuleConfig,
      useFactory: authConfigFactory,
      deps: [GlobalService],
    },
    {provide: OAuthStorage, useValue: localStorage},
    {provide: LOCALE_ID, useValue: 'uk-UA'},
    {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'}

  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
